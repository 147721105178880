import { FC } from 'react'

import { IImage, ILink } from '@/lib/shared/interfaces/topics'
import { Button, Card, CardHeader, Image, Link } from '@nextui-org/react'
import OptionalIcon from '@/components/contentful/action-button/optional-icon'
import ActionButton from '@/components/contentful/action-button/action-button'
import { motion, Variants } from 'framer-motion'

interface IBannerWithBg {
    img: IImage
    headline: string[]
    body: string
    actions?: ILink[] | null
    entryId: string
}

export function arrayCeil (arr: any, number: number) {
    const sorted = arr.sort((a: any, b: any) => a - b)

    for (let i = 0; i < arr.length; i++) {
        if (arr[i] > number) {
            return sorted[i]
        }
    }

    // If no index found return the last element
    return sorted[sorted.length - 1]
}

const heroTextVariants = {
    hidden: { x: '-100vw', opacity: 0 },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            type: 'spring',
            stiffness: 60,
            damping: 12
        }
    }
}

const floatingAnimation: Variants = {
    visible: {
        scale: [1, 1.2, 1, 1.1, 1], // Zoom in slightly and then zoom out
        x: ['0%', '0%', '0.5%', '0%'],
        transition: {
            duration: 80,
            repeat: Infinity,
            repeatType: 'mirror',
            ease: 'easeInOut'
        }
    }
}

const BannerWithBg: FC<IBannerWithBg> = ({
    img,
    headline,
    body,
    actions,
    entryId
}) => {
    return (
        <div className='relative'>
            <Card
                isBlurred
                radius='none'
                className='col-span-12 sm:col-span-4 h-[400px] lg:h-[600px] max-w-[1900px] m-auto '
            >
                <CardHeader className='absolute z-20 bottom-20 flex-col !items-start'>
                    <div className='relative max-w-screen-xl  w-full m-auto px-4   '>
                        <motion.div
                            initial='hidden'
                            animate='visible'
                            variants={heroTextVariants}
                            className=' w-full text-white '
                        >
                            <h1
                                data-contentful-entry-id={entryId}
                                data-contentful-field-id={'headline'}
                                className='text-3xl font-extrabold sm:text-5xl'
                            >
                                {headline[0]}
                                {headline[1] && (
                                    <strong className='block font-extrabold text-primary-500'>
                                        {headline[1]}
                                    </strong>
                                )}
                            </h1>

                            <p
                                data-contentful-entry-id={entryId}
                                data-contentful-field-id={'body'}
                                className='mt-4 max-w-lg sm:text-xl/relaxed'
                            >
                                {body}
                            </p>

                            <div
                                data-contentful-entry-id={entryId}
                                data-contentful-field-id={'actionButtons'}
                                className='mt-8 flex  flex-wrap gap-4 text-center'
                            >
                                {Array.isArray(actions) &&
                                    actions?.map((btn: ILink, itx: number) => {
                                        return (
                                            <ActionButton
                                                key={`${btn.url}-${itx}`}
                                                btn={btn}
                                            />
                                        )
                                    })}
                            </div>
                        </motion.div>
                    </div>
                </CardHeader>
                <div className='absolute w-full h-full object-cover z-10x z-10 opacity-30  bg-black'></div>

                <motion.div
                    className='absolute inset-0 z-0'
                    variants={floatingAnimation}
                    animate='visible'
                >
                    <Image
                        isZoomed={img?.enableZoom}
                        isBlurred={img.enableBlur}
                        sizes={img?.sizes || ''}
                        radius={img.radius}
                        removeWrapper
                        alt={img.alt}
                        className='z-0 w-full h-full object-cover !bg-opacity-5  backdrop-filter'
                        src={`${img.url}?fm=webp`}
                        fallbackSrc={`${img.url}?w=100`}
                    />
                </motion.div>
            </Card>
        </div>
    )
}

export default BannerWithBg
