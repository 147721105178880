import {
  IExternalUrl
} from "@/lib/contentful/interfaces/topics";
import { Link } from "@nextui-org/react";



const ExternalLinkWrapper = (entry: IExternalUrl) => {
    const url = entry?.fields?.url;
    const title = entry?.fields?.title;

    return (
        <span>
            <Link href={url} target="_blank">
                {title}
            </Link>
        </span>
    );
};

export default ExternalLinkWrapper;
