import React, { useRef, useEffect, FC } from 'react'
import videojs from 'video.js'
import Player from 'video.js/dist/types/player'
import 'videojs-youtube'

interface VideoJsOptions {
    autoplay?: boolean
    controls?: boolean
    responsive?: boolean
    fluid?: boolean
    audioOnlyMode?: boolean
    audioPosterMode?: boolean
    poster?: string
    sources?: { src: string; type: string }[]
}

interface VideoJsProps {
    options: VideoJsOptions
    onReady?: (player: Player) => void
}

const VideoJs: FC<VideoJsProps> = props => {
    // const videoRef = useRef(null)
    // const playerRef = useRef(null)
    // const { options, onReady } = props

    const videoRef = useRef<HTMLDivElement>(null)
    const playerRef = useRef<Player | null>(null)
    const { options, onReady } = props

    useEffect(() => {
        // Extract the source type for conditional tech order
        const isYouTube = options.sources?.some(
            src =>
                src.src.includes('youtube.com') || src.src.includes('youtu.be')
        )

        if (!playerRef.current && videoRef.current) {
            const videoElement = document.createElement('video')
            videoElement.classList.add('video-js', 'vjs-big-play-centered')
            videoRef.current.appendChild(videoElement)

            const player = (playerRef.current = videojs(
                videoElement,
                {
                    ...options,
                    ...(isYouTube ? { techOrder: ['youtube'] } : {})
                    // techOrder: isYouTube ? ['youtube'] : []
                },
                () => {
                    videojs.log('Player is ready')
                    if (onReady) {
                        onReady(player)
                    }
                }
            ))

            return () => {
                if (player && !player.isDisposed()) {
                    player.dispose()
                    playerRef.current = null
                }
            }
        } else if (playerRef.current) {
            const player = playerRef.current
            player.autoplay(options.autoplay)
            player.src(options.sources || [])
        }
    }, [options])

    return (
        <div data-vjs-player>
            <div ref={videoRef} />
        </div>
    )
}

export default VideoJs
