import React, { FC } from 'react'
import { Button, Link } from '@nextui-org/react'
import { ILink } from '@/lib/shared/interfaces/topics'
import OptionalIcon from './optional-icon'

interface IProps {
    btn: ILink
}

const ActionButton: FC<IProps> = ({ btn }) => {
    return (
        <Button
            as={Link}
            href={btn.url}
            isIconOnly={btn?.optionalIcon ? true : false}
            target={btn.openInNewTab ? '_blank' : '_self'}
            color={btn.color}
            variant={btn?.variant}
            size={btn?.size}
            className='p-1'
        >
            {btn?.optionalIcon ? (
                <OptionalIcon name={btn?.optionalIcon} />
            ) : (
                <>{btn?.label}</>
            )}
        </Button>
    )
}

export default ActionButton
