import React, { useRef, useState, useCallback, useEffect, FC } from 'react'
import { Card, CardBody, Image, Button, Slider } from '@nextui-org/react'
import VideoJs from '@/components/video-players/video-js'
import Player from 'video.js/dist/types/player'
import { throttle } from 'lodash'

import {
    FaHeart,
    FaPauseCircle,
    FaArrowRight,
    FaArrowLeft,
    FaInfinity,
    FaPlayCircle
} from 'react-icons/fa'
import { FaShuffle } from 'react-icons/fa6'

const formatTime = (timeInSeconds: number): string => {
    const hours = Math.floor(timeInSeconds / 3600)
    const minutes = Math.floor((timeInSeconds % 3600) / 60)
    const seconds = Math.floor(timeInSeconds % 60)

    if (hours > 0) {
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
            2,
            '0'
        )}:${String(seconds).padStart(2, '0')}`
    } else {
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
            2,
            '0'
        )}`
    }
}

interface IProps {
    title: string
    source: string
    byline?: string
}

const SamplePlayer: FC<IProps> = ({ title, source, byline }) => {
    const [liked, setLiked] = useState(false)
    const [seekTime, setSeekTime] = useState('')
    const [seekPercentage, setSeekPercentage] = useState(0)
    const playerRef = useRef<Player | null>(null)
    const [videoJsOptions, setVideoJsOptions] = useState({})
    const [isPlaying, setIsPlaying] = useState(false)

    const [duration, setDuration] = useState('')

    useEffect(() => {
        const videoJsOptionsDraft = {
            autoplay: false,
            controls: false,
            responsive: true,
            fluid: true,
            audioOnlyMode: false,
            audioPosterMode: false,
            poster: 'https://images.ctfassets.net/p2mu1i7m65u2/2OdmmKD7tBrKGF7dWsc7EC/91ff397da3a5601b3961b3dc91798086/Anonymous_3.webp',

            sources: [
                {
                    src: 'https://youtu.be/qJUEgKxeBpQ?si=pCcPISkIkwPbzfVy',
                    type: 'video/youtube'
                }
                // {
                //     src: 'https://videos.ctfassets.net/p2mu1i7m65u2/2Oe1zPGJmmzQZIEZ5FEH6U/5e352c69b8008ac67053699e3124b7d2/test.mp4',
                //     type: 'video/mp4'
                // }
            ]
        }

        setVideoJsOptions(videoJsOptionsDraft)

        return () => {}
    }, [])

    const handlePlayerReady = (player: Player) => {
        playerRef.current = player

        // Handle playing state
        player.on('play', () => {
            setIsPlaying(true)
            try {
                if (window?.fbq) {
                    window.fbq('trackCustom', 'VideoPlay', {
                        video_title: title,
                        duration: player.duration()
                    })
                }
            } catch (error) {}
        })

        player.on('pause', () => {
            setIsPlaying(false)
        })

        player.on('loadedmetadata', () => {
            const durationDraft = player.duration()
            setDuration(formatTime(durationDraft || 0) || '')
        })

        player.on('waiting', () => {
            // console.log('Player is waiting')
        })

        player.on('dispose', () => {
            setSeekPercentage(0)
        })

        // Listen to the `timeupdate` event to get the current time continuously
        player.on('timeupdate', () => {
            const currentTime = player.currentTime()
            const mediaDuration = player.duration()

            if (currentTime && mediaDuration) {
                setSeekPercentage((currentTime / mediaDuration) * 100)
            }

            const formattedTime = formatTime(currentTime || 0)
            setSeekTime(formattedTime)
        })
    }

    // Function to play the video
    const handlePlay = () => {
        if (playerRef.current) {
            playerRef.current.play()
        }
    }

    // Function to pause the video
    const handlePause = () => {
        if (playerRef.current) {
            playerRef.current.pause()
        }
    }

    // Function to get the current time
    const handleGetCurrentTime = useCallback(() => {
        if (playerRef.current) {
            const currentTime = playerRef.current.currentTime()

            // setSeekTime(currentTime || 0)
        }
    }, [])

    const handleRestart = () => {
        if (playerRef.current) {
            playerRef.current.currentTime(0) // Set the current time to 0
            playerRef.current.play() // Optionally, play the video immediately
        }
    }

    const handleJumpToEnd = () => {
        if (playerRef.current) {
            playerRef.current.currentTime(playerRef.current.duration()) // Set the current time to the duration
        }
    }

    if (!videoJsOptions) {
        return <></>
    }

    return (
        <div className='p-2  max-w-screen-xl w-full m-auto'>
            <Card isBlurred className='border-none bg-primary/60  ' shadow='md'>
                <CardBody>
                    <div className=' w-full grid grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 items-center justify-center'>
                        <div className='relative col-span-6 md:col-span-6'>
                            <VideoJs
                                options={videoJsOptions}
                                onReady={handlePlayerReady}
                            />

                            {/* <VideoJs options={videoJsOptions} onReady={handlePlayerReady} /> */}
                        </div>

                        <div className='flex flex-col col-span-6 md:col-span-6'>
                            <div className='flex justify-between items-start'>
                                <div className='flex flex-col gap-0'>
                                    <h3 className='font-semibold text-foreground/90'>
                                        {title}
                                    </h3>
                                    <p className='text-small text-foreground/80'>
                                        {byline}
                                    </p>
                                    <h1 className='text-large font-medium mt-2'>
                                        {source}
                                    </h1>
                                </div>
                                <Button
                                    isIconOnly
                                    className='text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2'
                                    radius='full'
                                    variant='light'
                                    onPress={() => setLiked(v => !v)}
                                >
                                    <FaHeart
                                        className={
                                            liked
                                                ? '[&>path]:stroke-transparent'
                                                : ''
                                        }
                                        fill={liked ? 'currentColor' : 'none'}
                                    />
                                </Button>
                            </div>

                            <div className='flex flex-col mt-3 gap-1'>
                                <Slider
                                    aria-label='Music progress'
                                    classNames={{
                                        track: 'bg-default-500/30',
                                        thumb: 'w-2 h-2 after:w-2 after:h-2 after:bg-foreground'
                                    }}
                                    size='md'
                                    step={0.01}
                                    color='warning'
                                    defaultValue={0}
                                    maxValue={100}
                                    minValue={0}
                                    value={seekPercentage}
                                />
                                <div className='flex justify-between'>
                                    <p className='text-small'>{duration}</p>
                                    <p className='text-small text-foreground/50'>
                                        {seekTime}
                                    </p>
                                </div>
                            </div>

                            <div className='flex w-full items-center justify-center'>
                                <Button
                                    isIconOnly
                                    className='data-[hover]:bg-foreground/10'
                                    radius='full'
                                    variant='light'
                                >
                                    <FaInfinity className='text-foreground/80' />
                                </Button>
                                <Button
                                    isIconOnly
                                    className='data-[hover]:bg-foreground/10'
                                    radius='full'
                                    variant='light'
                                    onClick={handleRestart}
                                >
                                    <FaArrowLeft />
                                </Button>

                                {isPlaying ? (
                                    <Button
                                        isIconOnly
                                        className='w-auto h-auto data-[hover]:bg-foreground/10 cursor-pointer'
                                        radius='full'
                                        variant='light'
                                        onClick={handlePause}
                                    >
                                        <FaPauseCircle size={54} />
                                    </Button>
                                ) : (
                                    <Button
                                        isIconOnly
                                        className='w-auto h-auto data-[hover]:bg-foreground/10 cursor-pointer'
                                        radius='full'
                                        variant='light'
                                        onClick={handlePlay}
                                    >
                                        <FaPlayCircle size={54} />
                                    </Button>
                                )}

                                <Button
                                    isIconOnly
                                    className='data-[hover]:bg-foreground/10 cursor-pointer'
                                    radius='full'
                                    variant='light'
                                    onClick={handleJumpToEnd}
                                >
                                    <FaArrowRight />
                                </Button>
                                <Button
                                    isIconOnly
                                    className='data-[hover]:bg-foreground/10 cursor-pointer'
                                    radius='full'
                                    variant='light'
                                >
                                    <FaShuffle className='text-foreground/80' />
                                </Button>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default SamplePlayer
