import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";

// import FieldRender from "@/components/Page/FieldRender";
import BlockEmbed from "./BLOCK/BlockEmbed";
import BoldText from "./BoldText";
import AssetHYPERLINK from "./INLINES/AssetHYPERLINK";
import HYPERLINK from "./INLINES/HYPERLINK";
import InlineEmbed from "./INLINES/InlineEmbed";
import RegularText from "./RegularText";
import RichTextCodeMark from "./RichTextCodeMark";
import RichTextTable from "./RichTextTable";
import RichTextTableCell from "./RichTextTableCell";
import RichTextTableHeaderCell from "./RichTextTableHeaderCell";
import RichTextTableRow from "./RichTextTableRow";

// const BlockEmbed = dynamic(()=> import("./BLOCK/BlockEmbed"), {ssr: false})

type NodeChildrenType = { node: any; children: any };

const OrderedList = ({ node, children }: NodeChildrenType) => {
    return <ol className="list-outside px-4 ml-4">{children}</ol>;
};
const UnorderedList = ({ node, children }: NodeChildrenType) => {
    return (
        <ul className=" marker:text-primaryx my-6 ml-6 list-discx [&>li]:mt-2 [&>li]:ml-4  ">
            {children}
        </ul>
    );
};

const ListItem = ({ children }: NodeChildrenType) => {
    return <li className="list-item text-left">{children}</li>;
};

const generateOptions = (props = {}) => {
    return {
        preserveWhitespace: true,
        renderMark: {
            [MARKS.CODE]: (text: any) => (
                <RichTextCodeMark>{text}</RichTextCodeMark>
            ),
            [MARKS.BOLD]: (text: any) => <BoldText>{text}</BoldText>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode = []) => (
                <RegularText node={node}>{children}</RegularText>
            ),

            [BLOCKS.TABLE]: (node: any, children: React.ReactNode = []) => {
                return <RichTextTable node={node}>{children}</RichTextTable>;
            },
            [BLOCKS.TABLE_ROW]: (node: any, children: React.ReactNode = []) => (
                <RichTextTableRow node={node}>{children}</RichTextTableRow>
            ),
            [BLOCKS.TABLE_CELL]: (
                node: any,
                children: React.ReactNode = []
            ) => <RichTextTableCell node={node}>{children}</RichTextTableCell>,
            [BLOCKS.TABLE_HEADER_CELL]: (
                node: any,
                children: React.ReactNode = []
            ) => (
                <RichTextTableHeaderCell node={node}>
                    {children}
                </RichTextTableHeaderCell>
            ),
            [BLOCKS.EMBEDDED_ENTRY]: (
                node: any,
                children: React.ReactNode = []
            ) => <BlockEmbed node={node} items={children} props={props} />,
            [BLOCKS.OL_LIST]: (node: any, children: React.ReactNode = []) => (
                <OrderedList node={node}>{children}</OrderedList>
            ),
            [BLOCKS.UL_LIST]: (node: any, children: React.ReactNode = []) => (
                <UnorderedList node={node}>{children}</UnorderedList>
            ),
            [BLOCKS.LIST_ITEM]: (node: any, children: React.ReactNode = []) => (
                <ListItem node={node}>{children}</ListItem>
            ),
            // [BLOCKS.HR]: (node: any, children: React.ReactNode = []) => (
            //   <>hr</>
            // ),
            // [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => (
            //   <EmbededAsset node={node} />
            // ),

            [INLINES.HYPERLINK]: (
                node: any,
                children: React.ReactNode = []
            ) => <HYPERLINK node={node}>{children}</HYPERLINK>,
            [INLINES.ASSET_HYPERLINK]: (
                node: any,
                children: React.ReactNode = []
            ) => <AssetHYPERLINK node={node}>{children}</AssetHYPERLINK>,
            // [INLINES.ENTRY_HYPERLINK]: (node: any, children: any) => (
            //   <EntryHYPERLINK node={node}>{children}</EntryHYPERLINK>
            // ),
            [INLINES.EMBEDDED_ENTRY]: (
                node: any,
                children: React.ReactNode = []
            ) => <InlineEmbed node={node} items={children} props={props} />,
        },

        // renderText: (text) => {
        //     return text.split("\n").reduce((children, textSegment, index) => {
        //         return [
        //             ...children,
        //             index > 0 && <br key={index} />,
        //             textSegment,
        //         ];
        //     }, []);
        // },

        // renderText: text =>
        //     text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    };
};

export default generateOptions;
