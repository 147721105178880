import React from "react";

import { componentMap } from "../richtext-embed-mappings";
import ExternalLinkWrapper from "@/components/contentful/external-link/external-link-wrapper";

import InlinePersonWrapper from "@/components/contentful/person/inline-person-wrapper";
import TopicDefinitionWrapper from "@/components/contentful/topic-definition/topic-definition-wrapper";
import PexelsMediaWrapper from "@/components/contentful/pexels-image-wrapper/pexels-image-wrapper";
import MediaWrapper from "@/components/contentful/media-wrapper/media-wrapper";
import VideoMediaWrapper from "@/components/contentful/video-wrapper/video-wrapper";
import ComponentResolver from "../../component-resolver";


const AllowedInlineEntriesLookup: any = {
    person: InlinePersonWrapper,
    topicDefinition: TopicDefinitionWrapper,
    imageWrapper: MediaWrapper,
    pexelsImageWrapper: PexelsMediaWrapper,
    externalLink: ExternalLinkWrapper
};
type Props = {
    items: any;
    node: any;
    props: any;
};

const InlineEmbed = ({ node, items, props }: Props) => {
 
    const entry = node?.data?.target;

    const entryId = entry?.sys?.id;
    const contentType: string = entry?.sys?.contentType?.sys?.id;
    const Component = AllowedInlineEntriesLookup[contentType];

    if (!Component) {
        return <span>!{contentType}</span>;
    }

    // if (contentType === "imageWrapper" || contentType === "pexelsImageWrapper") {
    //     return (
    //         <span className="float-right w-10/12 ">
    //             <Component {...entry} {...props} />
    //         </span>
    //     );
    // }

    return <Component {...entry} {...props} />;

};


export default InlineEmbed;
