import React, { FC } from 'react'
import { IExternalUrl } from '@/lib/contentful/interfaces/topics'
import {
    FaTiktok,
    FaYoutube,
    FaSpotify,
    FaApple,
    FaFacebook,
    FaInstagram,
    FaTwitter,
    FaGithub,
    FaYoutubeSquare,
    FaLinkedin,
    FaIcons
} from 'react-icons/fa'

interface IProps {
    name: IExternalUrl['fields']['optionalIcon']
}

const iconMap: Record<string, JSX.Element> = {
    Spotify: <FaSpotify className='w-full h-full' />,
    TikTok: <FaTiktok className='w-full h-full' />,
    Youtube: <FaYoutube className='w-full h-full' />,
    'Apple Music': <FaApple className='w-full h-full' />,
    Facebook: <FaFacebook className='w-full h-full' />,
    Instagram: <FaInstagram className='w-full h-full' />,
    Twitter: <FaTwitter className='w-full h-full' />,
    Github: <FaGithub className='w-full h-full' />,
    'Youtube Music': <FaYoutubeSquare className='w-full h-full' />,
    LinkedIn: <FaLinkedin className='w-full h-full' />
}

const OptionalIcon: FC<IProps> = ({ name }) => {
    if (!name) return <></>
    return iconMap[name] || <FaIcons className='w-full h-full' />
}

export default OptionalIcon
