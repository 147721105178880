import BannerWithBg from '@/components/ui/hero-banner/banner-with-bg/banner-with-bg'
import Banner from '@/components/ui/hero-banner/banner/banner'
import SplitWithContentImage from '@/components/ui/hero-banner/split-with-content-image/split-with-content-image'
import {
    retrieveImageUrlFromMediaWrapper,
    transformBaseButtonToLink
} from '@/lib/contentful/helpers/common'
import { IHeroBanner, IMusicCard } from '@/lib/contentful/interfaces/components'
import { ILink } from '@/lib/shared/interfaces/topics'
import { useContentfulInspectorMode } from '@contentful/live-preview/react'
import { Skeleton } from '@nextui-org/react'
import { ISong } from '@/lib/contentful/interfaces/page'
import { FC } from 'react'
import SongPreview from '../song-preview/song-preview'

const MusicCardWrapper: FC<IMusicCard> = entry => {
    const inspectorProps = useContentfulInspectorMode()
    const variant = entry?.fields?.variant
    const headline = (entry?.fields?.title as string) || ''

    // const imageData = retrieveImageUrlFromMediaWrapper(entry?.fields?.image);
    const content = entry?.fields?.content
    const title = content?.fields?.title
    const slug = content?.fields?.slug

    return (
        <div className=''>
            <SongPreview song={content} />
            {/* {variant === 'Simple' && <>SImple card</>}
            {variant === 'Full Bleed' && <>full bleed card</>} */}
        </div>
    )
}

export default MusicCardWrapper
