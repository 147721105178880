import React, { FC } from 'react'
import { ISong } from '@/lib/contentful/interfaces/page'
import SamplePlayer from './sample-player'
import VideoJs from '@/components/video-players/video-js'
import { join } from 'lodash'
import { dateFormatter } from '@/lib/contentful/helpers/common'
import { Button, Link } from '@nextui-org/react'
import OptionalIcon from '../action-button/optional-icon'
import { motion, useInView } from 'framer-motion'
import {
    retrieveImageUrlFromMediaWrapper,
    transformBaseButtonToLink
} from '@/lib/contentful/helpers/common'
import { ILink } from '@/lib/shared/interfaces/topics'
import { IExternalUrl } from '@/lib/contentful/interfaces/topics'

interface IProps {
    song: ISong
}

const SongPreview: FC<IProps> = ({ song }) => {
    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0, y: -50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
    }

    const buttonVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: (i: number) => ({
            opacity: 1,
            y: 0,
            transition: { delay: i * 0.1, duration: 0.5 }
        })
    }

    return (
        <div className='py-10 bg-primary-50/60 flex flex-col gap-4 p-2'>
            <motion.div
                initial='hidden'
                animate='visible'
                variants={containerVariants}
                className='grid grid-cols-3 gap-4 max-w-md m-auto'
            >
                {song?.fields?.streamingLinks?.map((btn, snglnkx) => {
                    return (
                        <motion.div
                            key={`key-${snglnkx}`}
                            variants={buttonVariants}
                            custom={snglnkx}
                            whileHover={{ scale: 1.3 }}
                        >
                            <Button
                                as={Link}
                                href={btn?.fields?.url}
                                isIconOnly={
                                    btn?.fields?.optionalIcon ? true : false
                                }
                                target={'_blank'}
                                className='p-1'
                            >
                                {btn?.fields?.optionalIcon ? (
                                    <OptionalIcon
                                        name={btn?.fields?.optionalIcon}
                                    />
                                ) : (
                                    <>{btn?.fields?.title}</>
                                )}
                            </Button>
                        </motion.div>
                    )
                })}
            </motion.div>
            <motion.div
                initial='hidden'
                animate='visible'
                variants={containerVariants}
                className=' '
            >
                <SamplePlayer
                    title={song?.fields?.title}
                    byline={dateFormatter(song?.fields?.releaseDate)}
                    source={join(song?.fields?.composers, ', ')}
                />
            </motion.div>
        </div>
    )
}

export default SongPreview
