import React from "react";

type Props = {
  children: any;
  node: any;
};

const RegularText = ({ node, children }: Props) => {


  if (
    node.content.length === 1 &&
    node.content[0].marks.find((x: any) => x?.type === "code")
  ) {
    return <div className="">{children}</div>;
  }


  return (
    <p className="leading-loose">
      {Array.isArray(children) && children.map((line: any, lx: number) => {
        if (!line) {
          
          return <br key={lx} />;
        } else {
          return line;
        }
      })}
    </p>
  );
};

export default RegularText;
