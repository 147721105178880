import { ILink } from '@/lib/shared/interfaces/topics'
import { Button, Link } from '@nextui-org/react'
import { FC } from 'react'
import ActionButton from '@/components/contentful/action-button/action-button'

interface ISplitWithContentImageMeta {
    title: string
    body: string
    actions?: any
    entryId: string
}
const MetaText: FC<ISplitWithContentImageMeta> = ({
    title,
    body,
    actions,
    entryId
}) => {
    return (
        <div className='lg:py-24'>
            <h2
                data-contentful-entry-id={entryId}
                data-contentful-field-id={'headline'}
                className='text-3xl font-bold sm:text-4xl'
            >
                {title}
            </h2>

            <p
                data-contentful-entry-id={entryId}
                data-contentful-field-id={'body'}
                className='mt-4'
            >
                {body}
            </p>
            <div
                data-contentful-entry-id={entryId}
                data-contentful-field-id={'actionButtons'}
                className='mt-8 flex  flex-wrap gap-4 text-center'
            >
                {Array.isArray(actions) &&
                    actions?.map((btn: ILink, itx: number) => {
                        return (
                            <ActionButton key={`${btn.url}-${itx}`} btn={btn} />
                        )
                    })}
            </div>
        </div>
    )
}

export default MetaText
