import { IImageWrapper, IPexelsImageWrapper } from "../interfaces/topics";
import { IImage } from "@/lib/shared/interfaces/topics";
import { formatDistanceToNow } from "date-fns";

import { ILink } from "@/lib/shared/interfaces/topics";
import { IBaseButton } from "@/lib/contentful/interfaces/blocks";
import { IExternalUrl } from "@/lib/contentful/interfaces/topics";
import {
    ILandingPage,
    ICategoryPage,
    IBlogPostPage,
    ICoursePage,
    ILesson,
} from "@/lib/contentful/interfaces/page";
import { Entry, EntryFields, Asset } from "contentful";

export const extractCtfImageUrl = (image: Asset | null): string => {
    const url: string = image?.fields?.file?.url?.toString() || "";
    if (url) return url;
    return "";
};

function generatePexelsImageSizes(src: any): string {
    return `
    (min-width: 1200px) ${src.large2x} 2x, ${src.large},
    (min-width: 992px) ${src.large},
    (min-width: 768px) ${src.medium},
    (min-width: 576px) ${src.small},
    (orientation: portrait) ${src.portrait},
    (orientation: landscape) ${src.landscape},
    ${src.tiny}
  `;
}

export const retrieveImageUrlFromMediaWrapper = (
    media: IPexelsImageWrapper | IImageWrapper
): IImage => {
    try {
        if (!media) {
            return {
                url: "",
                alt: "",
            };
        }
        const featuredImgType = media.sys.contentType.sys.id;
        const radiusMap: {
            [key: string]: "none" | "sm" | "md" | "lg" | "full";
        } = {
            None: "none",
            Small: "sm",
            Medium: "md",
            Large: "lg",
            Full: "full",
        };

        const getRadius = (radiusVal?: string) =>
            radiusMap[radiusVal || "None"];

        if (featuredImgType === "imageWrapper") {
            const data = media as IImageWrapper;
            if (!data.fields?.image) {
                return {
                    url: "",
                    alt: "",
                };
            }
            return {
                url: (data.fields.image.fields.file?.url as string) || "",
                alt: data.fields.altText,
                enableZoom: data.fields?.enableZoom,
                enableBlur: data.fields?.enableBlur,
                radius: getRadius(data.fields?.radius),
            };
        }

        if (featuredImgType === "pexelsImageWrapper") {
            const data = media as IPexelsImageWrapper;
            const pexelsImage = data.fields.pexelsImage;
            const sizes = generatePexelsImageSizes(pexelsImage.src);
            return {
                url: pexelsImage?.src.large || pexelsImage?.src.original,
                alt: pexelsImage?.alt || "",
                enableZoom: data.fields?.enableZoom,
                enableBlur: data.fields?.enableBlur,
                radius: getRadius(data.fields?.radius),
                sizes: sizes,
            };
        }

        return { url: "", alt: "" };
    } catch (error) {
        return { url: "", alt: "" };
    }
};

export const retrieveUrlFromTarget = (
    target:
        | IExternalUrl
        | ILandingPage
        | ICategoryPage
        | IBlogPostPage
        | ICoursePage
        | ILesson
) => {
    const contentType = target?.sys?.contentType?.sys?.id;
    if (contentType === "landingPage") {
        const entry = target as ILandingPage;
        if (
            entry?.fields?.slug === "homepage" ||
            entry?.fields?.slug === "home"
        ) {
            return "/";
        }

        return `/${entry?.fields?.slug}`;
    }

    if (contentType === "externalLink") {
        const entry = target as IExternalUrl;

        return `${entry?.fields?.url}`;
    }
    if (contentType === "categoryPage") {
        const entry = target as ICategoryPage;

        return `/category/${entry?.fields?.slug}`;
    }
    if (contentType === "blogPostPage") {
        const entry = target as IBlogPostPage;

        return `/blog/${entry?.fields?.category?.fields?.slug}/${entry?.fields?.slug}`;
    }

    if (contentType === "course") {
        const entry = target as ICoursePage;

        return `/course/${entry?.fields?.slug}`;
    }
    if (contentType === "lesson") {
        const entry = target as ILesson;

        return `/lesson/${entry?.fields?.slug}`;
    }

    return "";
};

export const dateFormatter = (date: any) => {
    try {
        const inputDate = new Date(date);
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
            // timeZoneName: "short",
        };

        const formattedDate: string = inputDate.toLocaleDateString(
            "en-US",
            options
        );

        return formattedDate;
    } catch (error) {}
    return "";
};

export const transformBaseButtonToLink = (button: IBaseButton): ILink => {
    const colorMap: {
        [key: string]:
            | "default"
            | "primary"
            | "secondary"
            | "success"
            | "danger"
            | "warning";
    } = {
        Default: "default",
        Primary: "primary",
        Secondary: "secondary",
        Success: "success",
        Danger: "danger",
        Warning: "warning",
    };

    const variantMap: {
        [key: string]:
            | "solid"
            | "bordered"
            | "light"
            | "flat"
            | "faded"
            | "shadow"
            | "ghost";
    } = {
        Solid: "solid",
        Bordered: "bordered",
        Light: "light",
        Flat: "flat",
        Faded: "faded",
        Shadow: "shadow",
        Ghost: "ghost",
    };

    const sizeMap: {
        [key: string]: "sm" | "md" | "lg";
    } = {
        Small: "sm",
        Medium: "md",
        Large: "lg",
    };

    const getColor = (val?: string) => colorMap[val || "Default"];
    const getVariant = (val?: string) => variantMap[val || "Solid"];
    const getSize = (val?: string) => sizeMap[val || "Medium"];

    const link: ILink = {
        label: button?.fields?.label,
        url: retrieveUrlFromTarget(button?.fields?.target),
        color: getColor(button?.fields?.color),
        variant: getVariant(button?.fields?.variant),
        size: getSize(button?.fields?.size) || "md",
        openInNewTab: button?.fields?.openInNewTab,
        optionalIcon: button?.fields?.target?.fields?.optionalIcon,
    };

    return link;
};

export const isEmptyRichTextField = (richText: any) => {
    // Check if the richText object is empty

    if (!richText || !richText.content || richText.content.length === 0) {
        return true;
    }

    const isEmpty = richText.content.every((node: any) => {
        if (node.nodeType === "text" && node.value) {
            return node.value.trim() === "";
        } else if (node.nodeType === "embedded-entry-block") {
            return false; // If it's an embedded entry block (such as an image), it's not empty
        } else if (node.content) {
            return isEmptyRichTextField(node);
        }
        return true; // If it's not a text node and doesn't have content, consider it empty
    });

    return isEmpty;
};
export const transformTimestamp = (timestamp: string) => {
    return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
};

export function extractYouTubeVideoID(url: string) {
    // const regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
    // const match = url.match(regex);
    // return match ? match[1] : null;

    const regex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
}

export const secondsToMinutes = (seconds: number) => {
    const minutes: number = Math.floor(seconds / 60);
    const remainingSeconds: number = Math.round(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};
